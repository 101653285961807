import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { BModal } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/styles/main.scss';

import 'swiper/swiper.min.css';

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

Vue.component('b-modal', BModal);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
