<template>
  <b-modal v-model="modalState"
           hide-footer>
    <div class="d-block position-relative">
      <h5>
        Email
      </h5>
      <div>
        <div class="mb-4">
          <label for="email" class="mb-2">
            Enter your email to get your access details
          </label>
          <input ref="emailInput"
                 id="email"
                 class="form-control email-field"
                 placeholder="example@gmail.com"
                 type="email"
                 @input="validateEmail"/>
          <div ref="alert" class="invalid-feedback fade hide">
            Enter your email please
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button ref="sendBtn"
                  class="btn btn-block site-btn site-btn_primary disabled"
                  type="button"
                  @click="sendEmail">
            Continue
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
function getCookie(name) {
  // eslint-disable-next-line
  const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export default {
  name: 'EmailModal',
  props: [
    'isModalShow',
  ],
  computed: {
    modalState: {
      get() {
        return this.isModalShow;
      },
      set(value) {
        this.$emit('onstatechange', value);
      },
    },
  },
  methods: {
    validateEmail(event) {
      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (validateEmail(event.target.value?.trim())) {
        this.$refs.emailInput.classList.remove('is-invalid');
        this.$refs.sendBtn.classList.remove('disabled');
        this.$refs.alert.classList.replace('show', 'hide');
      } else {
        this.$refs.emailInput.classList.add('is-invalid');
        this.$refs.sendBtn.classList.add('disabled');
        this.$refs.alert.classList.replace('hide', 'show');
      }
    },
    sendEmail() {
      if (this.$refs.emailInput.value?.trim()) {
        this.registerUser(
          this.$refs.emailInput.value?.trim(),
          document.querySelector('#eventId').innerText.trim().includes('{{')
            ? '23239767'
            : document.querySelector('#eventId').innerText.trim(),
        )
          .then((regResp) => {
            console.log(regResp);
            if (!regResp.message) {
              this.getCredentials()
                .then((resp) => {
                  this.$store.commit('setCredentials', resp.data);
                  console.log(this.$store.state.credentials);
                  this.$router.push('/success')
                    .catch(() => {
                    });
                });
            } else {
              this.$router.push('/success')
                .catch(() => {
                });
            }
          });
      }
    },
    registerUser(email, eventId) {
      return fetch('https://expertchat.mathmaster.me/api/user/register/email', {
        method: 'POST',
        body: JSON.stringify({
          email,
          event_id: eventId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.data.access_token && resp.data.user_uuid) {
            this.createCookie('access_token', resp.data.access_token, 5);
            this.createCookie('user_uuid', resp.data.user_uuid, 5);
          }
          return resp.data;
        });
    },
    getCredentials() {
      const formData = new FormData();
      formData.append('access_token', getCookie('access_token'));
      formData.append('user_uuid', getCookie('user_uuid'));
      return fetch('https://expertchat.mathmaster.me/api/user/credentials', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
    createCookie(name, value, minutes = 0) {
      const date = new Date();
      date.setTime(date.getTime() + (minutes * 60 * 1000));
      const expires = `; expires=${date.toGMTString()}`;
      document.cookie = `${name}=${value}${expires}; path=/`;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &.show {
    display: block;
  }

  &-head {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #5B5B5B;
    margin-bottom: 24px;
  }

  &-loading-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    z-index: 101;
  }

  &-pay-button {
    &__delimiter {
      font-size: 17px;
      line-height: 25px;
      color: #ACB2C0;
      text-align: center;
      margin: 0;
      position: relative;
      padding: 32px 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40%;
        height: 1px;
        background: #9F9F9F;
      }
    }
  }
}
</style>
